<template>
    <div class="main-pdding">
        <div class="main-box rotation">
            <div class="title">
                <div class="t-label">
                    <label @click="router.push('/productMent/list')">{{title}}</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png">
                <div class="t-label current">
                    <label>编辑</label>
                </div>
            </div>
            <div class="edit-form">
                <el-form :hide-required-asterisk="true" ref="editform" :model="editData" label-width="150px" :rules="rules">
                    <el-form-item label="缩略图">
                        <addImage @upimgSuccess="upimgSuccess" :imgUrl="editData.url"></addImage>
                    </el-form-item>
                    <el-form-item label="产品ID" v-if="type == 1" prop="productId">
                        <el-input style="width:20%" v-model.number="editData.productId" ></el-input>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input style="width:20%" v-model.number="editData.sort" ></el-input>
                    </el-form-item>
                    <el-form-item label="点击幻灯片动作">
                        <div>
                            <el-radio v-model="editData.radio" label="1">点击无效果</el-radio>
                            <!-- <el-radio v-model="editData.radio" label="2">点击跳转</el-radio> -->
                        </div>
                    </el-form-item>
                    <!-- <div style="min-height:50px;">
                        <el-form-item v-if="editData.radio == 2" prop="link">
                                <el-input  placeholder="请输入内容" v-model="editData.link" :disabled="true">
                                    <el-button type="primary" slot="append" @click="dialogVal= true">选择链接</el-button>
                                </el-input>
                        </el-form-item>
                    </div> -->
                </el-form>
            </div>
            <div class="bottom-btn">
                <el-button @click="$router.push('/rotation/rotation')">取消</el-button>
                <el-button type="primary" @click="submit">确定</el-button>
            </div>
        </div>
        <jumpLink :dialogVal="dialogVal" @dialogChange="linkChange">
        </jumpLink>
    </div>
</template>

<script>
    import rules from '@/utils/rules'
    import addImage from '@/components/addIimage/addImg.vue'
    import jumpLink from '@/components/dialog/jumpLink'
    import {decrypt} from '@/utils/encry.js'
    import { sessionGetKey } from "@/utils/sessionStorage.js";
    export default {
        components:{
            addImage,
            jumpLink
        },
        mounted(){
            if(this.$route.query.data){
                const data = JSON.parse(decrypt(this.$route.query.data))
                this.editData.sort = data.sort
                this.editData.url = data.url
                this.editData.id = data.id
                if(data.cate_id){
                    this.editData.productId = data.cate_id
                }
                console.log(data,'datattt');
            }
            this.title = this.$route.query.title
            this.type = this.$route.query.type
            this.platform_id = sessionGetKey('platformId')
        },
        data(){
            return{
                rules:rules,
                dialogVal:false,
                title:'首页轮播图',
                type:'',   //0为首页轮播图，1为产品轮播图
                platform_id:'',
                editData:{
                    sort:'',
                    radio:'1',
                    link:'',
                    url:'',
                    productId:''
                }
            }
        },
        methods:{
            linkChange(type,data){
                console.log(type,data,'datadxta');
                this.dialogVal= false
                if(type == 1){
                    this.editData.link = data.label
                }
            },
            // 确定
            submit(){
                const that = this
                const typeValue = ''
                let data = {}
                if(that.editData.url === ''){
                    that.$alert('请上传一张轮播图片','', {})
                    return
                }

                if(that.type == 0){ 
                    data = {
                        idp:parseInt(that.editData.id),
                        type:parseInt(that.type),
                        sort:that.editData.sort,
                        url:that.editData.url,
                        is_delete: 0
                    }
                }else if(that.type == 1){
                    data = {
                        idp:parseInt(that.editData.id),
                        type:parseInt(that.type),
                        id:parseInt(that.editData.productId),
                        sort:that.editData.sort,
                        url:that.editData.url,
                        is_delete: 0
                    }
                }

                that.$refs.editform.validate((valid) => {
                    if(valid){
                        that.$request({
                            url:that.$api.rotantion.editRotan,
                            method:'post',
                            data:data,
                            params:{
                                platform_id:that.platform_id
                            }
                        }).then(res=>{
                            that.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            that.$router.push('/rotation/rotation')
                        })
                    }
                })
                
            },
            // 上传图片成功或删除图片
            upimgSuccess(imgUrl){
                this.editData.url = imgUrl
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rotation{
        // position: relative;
    }

    .title {
        .t-label {
            display: inline-block;
            height: 35px;

            label {
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
                font-weight: 600;
            }
        }

        img {
            vertical-align: middle;
            width: 24px;
            height: 24px;
        }

        .current {
            
            border-bottom: 2px solid #4458FE;
            label{
                color: #4458FE;
            }
        }
    }

    .edit-form{
        width: 500px;
        margin-left: 100px;
        margin-top: 30px;
    }

    .bottom-btn{
        margin: 100px 0 0 150px;
    }
</style>